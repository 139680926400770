import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { MD_LAYOUT_BREAKPOINT } from '../../themes/GlobalThemeProvider';
import { colorPalette } from 'ri-components';
import './navBarStyle.css';
import IceLogo from './ice-logo.png';
import useLogout from '../../auth/useLogout';

const { black, grey } = colorPalette;

const useStyles = makeStyles((theme) => ({
  rightMenuButtonGroup: {
    marginLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    height: '72px',
    boxShadow: `0 12px 16px -8px ${grey[3]}`,
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    height: '100%',
    padding: theme.spacing(2, 2.5),
    width: '100%',
    maxWidth: '1200px',
    [theme.breakpoints.between(0, MD_LAYOUT_BREAKPOINT)]: {
      justifyContent: 'space-between',
      padding: theme.spacing(1.75, 2.5, 2.25),
    },
  },
  flexGrow: {
    flexGrow: '1',
  },
  modal: {
    width: '200vw',
    height: '200vh',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: black,
    opacity: 0.5,
    zIndex: 5,
  },
}));

const Navbar = () => {
  const classes = useStyles();

  const [isDisplayModal, setIsDisplayModal] = useState(false);
  const onLogout = useLogout();

  return (
    <div className={classes.flexGrow}>
      {isDisplayModal && <div className={classes.modal} data-testid='modal-background' />}
      <div className="page-wrapper" id="page-wrapper">
        <header className="header" id="header">
          <nav id="primaryNav" className="primary-nav">
            <div className="container-fluid primary-nav__container">
              <img width="152"  src={IceLogo} alt="Ice" />
              <button id="primaryNavToggle" className="primary-nav__toggler" type="button"
                aria-controls="primaryNavCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="primary-nav__toggle-menu">
                  <svg viewBox="0 0 800 600" className="primary-nav-btn__icon">
                    <path
                      d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                      className="top-bar"></path>
                    <path d="M300,320 L540,320" className="middle-bar"></path>
                    <path
                      d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                      className="bottom-bar"
                      transform="translate(480, 320) scale(1, -1) translate(-480, -318)"></path>
                  </svg>
                </span>
              </button>
              <div id="primaryNavCollapse" className="primary-nav__collapse js-site-search-mobile-location">
                <ul className="primary-nav__menu">
                  <li className="primary-nav__item">
                    <a href="/" className="primary-nav__link policies policiesLink" target="_top" title="Policies">
                      My Policies
                    </a>
                  </li>

                  <li className="primary-nav__item">
                    <button onClick={onLogout} className="primary-nav__link primary-nav__link--highlight policyBtn" target="_top" title="Logout">
                      Logout
                    </button>
                  </li>
                </ul>

              </div>
            </div>
          </nav>
        </header>
      </div>
    </div>
  );
};

export default Navbar;

Navbar.defaultProps = {
  claimListLabel: 'Claim List',
  adminLabel: 'Admin',
};

Navbar.propTypes = {
  claimListLabel: PropTypes.string,
  adminLabel: PropTypes.string,
  searchPlaceholder: PropTypes.string,
};
